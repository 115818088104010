import * as React from "react"
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import ogMetaImage from '../../static/entratalogo_1200x630.png'
import '../styles/text-page-styles.css'

export default function IntegrationsPage({data}) {
  const GroupLinks = data?.contentfulLinkPageTemplate?.groupOfLinks
  return (
    <Layout>
    <div className="integrations text-page-styles">
      <div className="content-cap">
        <div className="text-page-intro">
          <h1>{data?.contentfulLinkPageTemplate?.linkPageIntro?.introTitle}</h1>
          <p>{data?.contentfulLinkPageTemplate?.linkPageIntro?.introText}</p>
        </div>
        <div className="text-page-content">
        {GroupLinks.map((groupLink) => {
          const LinksList = groupLink?.listOfLinks
          return(
            <span key={groupLink?.id} className="link-group">
              <div className="text-title">{groupLink?.sectionTitle}</div>
              <ul>
                {LinksList.map((linkList) => {
                  return(
                    <li key={linkList?.id}>
                      <a href={linkList?.fullUrl} target="_blank" rel="nofollow noopener noreferrer">{linkList?.text}</a>
                    </li>
                  )
                })}
              </ul>
            </span>
          )
        })}
        </div>
      </div>
    </div>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <meta property="og:title" content="Entrata | Integrations" />
    <meta property="og:description" content="We play nice with others." />
    <meta property="og:url" content="https://www.entrata.com/integrations" />
    <meta name="description" content="We play nice with others." />
    <title>Entrata | Integrations</title>
    <meta property="og:image" content={ogMetaImage} />
  </>
)

export const query = graphql`
  query IntegrationsPageContent {
    contentfulLinkPageTemplate(title: {eq: "Integrations Page"}) {
      id
      metaTitle
      metaDescription
      ogMetaTitle
      ogMetaDescription
      linkPageIntro {
        introText
        introTitle
      }
      groupOfLinks {
        sectionTitle
        id
        listOfLinks {
          fullUrl
          slug
          text
          id
        }
      }
    }
  }
`
